
import { Component, Vue } from 'vue-property-decorator';
import { AdminService } from '@/services';

// Types
import { IAdminOffer, IEmailCustomResponse, ISmsCustomResponse } from '@/types/admin.types';
import { IAuthUser } from '@/types/user.types';

// User Info
import { UserModule } from '@/store/modules';

@Component({
  components: {}
})
export default class AdminOffers extends Vue {
  allOffers: IAdminOffer[] = [];

  loading = false;
  editedIndex = -1;
  dialog = false;
  emailDialog = false;
  smsDialog = false;
  coverImageFile = null;
  uploadedImage: any = '';
  isImageUploading = false;
  brandName = '';
  selectedOfferId = '';
  isTestEmailVisible = false;
  isTestSmsVisible = false;
  isSendingTestEmail = false;

  headers = [
    { text: 'OFFER', value: `brand.name` },
    { text: 'REACH', value: `reach` },
    { text: 'COVER IMAGE', value: 'coverImg' },
    { text: 'Email', value: 'email', sortable: false },
    { text: 'SMS', value: 'sms', sortable: false },
    { text: 'Actions', value: 'actions', sortable: false }
  ];

  defaultData = {
    id: '',
    budget: '',
    coverImg: '',
    createdAt: '',
    currency: '',
    daysAvailability: '',
    description: '',
    purchaseAmount: '',
    purchaseFrequency: 0,
    qualifier: '',
    reach: '',
    redeemLimitPerUser: 0,
    redemptionTrigger: '',
    redemptionType: '',
    rewardMaxValue: '',
    rewardType: '',
    rewardValue: '',
    startDate: '',
    tile: '',
    title: '',
    totalRedeemLimit: 0,
    updatedAt: '',
    brand: {}
  };

  editedItem: IAdminOffer = this.defaultData;
  defaultItem: IAdminOffer = this.defaultData;
  defaultEditCustomEmail: IEmailCustomResponse = {
    id: '',
    subject: '',
    body: '',
    offerId: ''
  };
  defaultEditCustomSms: ISmsCustomResponse = {
    id: '',
    sms: '',
    offerId: ''
  };
  editCustomEmail = this.defaultEditCustomEmail;
  editCustomSms = this.defaultEditCustomSms;
  testNotificationItemId?: string;

  testPurchase = 0;
  testMerchant = '';
  testDonation = 0;

  get user(): IAuthUser | null {
    return UserModule.user;
  }

  async customizeEmail(item: IAdminOffer): Promise<void> {
    this.testNotificationItemId = item.id;
    this.editCustomEmail = {
      id: '',
      subject: '',
      body: '',
      offerId: ''
    };
    this.brandName = item.brand.name;
    this.selectedOfferId = item.id;
    this.emailDialog = true;
    this.editCustomEmail = await AdminService.fetchCustomEmail(item.id);
  }

  async customizeSMS(item: IAdminOffer): Promise<void> {
    this.testNotificationItemId = item.id;
    this.editCustomSms = {
      id: '',
      sms: '',
      offerId: ''
    };
    this.brandName = item.brand.name;
    this.selectedOfferId = item.id;
    this.smsDialog = true;
    this.editCustomSms = await AdminService.fetchCustomSms(item.id);
  }

  editItem(item: IAdminOffer): void {
    this.editedIndex = this.allOffers.indexOf(item);
    this.editedItem = Object.assign({}, item);
    this.dialog = true;
  }

  close(): void {
    this.dialog = false;
    this.emailDialog = false;
    this.smsDialog = false;
    this.testNotificationItemId = undefined;
    this.$nextTick(() => {
      this.editedItem = Object.assign({}, this.defaultItem);
      this.editedIndex = -1;
    });
  }

  onFilePicked(e: any): void {
    this.coverImageFile = e.target.files[0];

    const fr = new FileReader();
    fr.readAsDataURL(e.target.files[0]);
    fr.addEventListener('load', () => {
      this.uploadedImage = fr.result;
    });
  }

  async save(): Promise<any> {
    if (this.coverImageFile !== null) {
      this.isImageUploading = true;
      let formData = new FormData();
      formData.append('coverImage', this.coverImageFile as any);
      await AdminService.uploadOfferCoverImage(formData, this.editedItem.id);
      this.isImageUploading = false;
      this.uploadedImage = '';
      this.close();
      this.getAdminOffers();
    }
  }

  async saveCustomEmail(): Promise<any> {
    const emailPayload: IEmailCustomResponse = {
      subject: this.editCustomEmail.subject,
      body: this.editCustomEmail.body
    };

    if (this.editCustomEmail?.id) {
      await AdminService.updateCustomEmail(this.editCustomEmail.id, emailPayload);
      this.$notifySuccess(`Email for ${this.brandName} has been updated successfully!`);
    } else {
      emailPayload.offerId = this.selectedOfferId;
      await AdminService.addCustomEmail(emailPayload);
      this.$notifySuccess(`Email for ${this.brandName} has been added successfully!`);
    }
    this.close();
    this.getAdminOffers();
  }

  async saveCustomSms(): Promise<any> {
    const smsPayload: ISmsCustomResponse = {
      sms: this.editCustomSms.sms
    };
    if (this.editCustomSms?.id) {
      await AdminService.updateCustomSms(this.editCustomSms.id, smsPayload);
      this.$notifySuccess(`SMS for ${this.brandName} has been updated successfully!`);
    } else {
      smsPayload.offerId = this.selectedOfferId;
      await AdminService.addCustomSms(smsPayload);
      this.$notifySuccess(`SMS for ${this.brandName} has been added successfully!`);
    }
    this.close();
    this.getAdminOffers();
  }

  isTestEmailInputSet(): boolean {
    let isVariablesSet = false;

    if (this.testPurchase && this.testMerchant && this.testDonation) {
      isVariablesSet = true;
    }
    return isVariablesSet;
  }

  processTestEmail(): void {
    this.isTestEmailVisible = !this.isTestEmailVisible;
  }

  processTestSms(): void {
    this.isTestSmsVisible = !this.isTestSmsVisible;
  }

  async sendTestEmail(): Promise<any> {
    this.isSendingTestEmail = true;

    if (!this.editCustomEmail.subject) {
      this.isSendingTestEmail = false;
      this.$notifyError('Email Subject is required');
    }

    if (!this.testPurchase || !this.testDonation || !this.testMerchant) {
      this.isSendingTestEmail = false;
      this.$notifyError('All test email inputs are required');
    }

    const testEmailPayload = {
      body: this.editCustomEmail.body,
      emailParams: {
        donationAmount: Number(this.testDonation),
        offerId: this.testNotificationItemId,
        storeName: this.testMerchant,
        transactionAmount: Number(this.testPurchase)
      },
      emailType: 'email_template_offer_redemption',
      subject: this.editCustomEmail.subject
    };
    await AdminService.sendTestEmail(testEmailPayload);

    this.isSendingTestEmail = false;
  }

  async sendTestSms(): Promise<void> {
    if (!this.testPurchase || !this.testDonation || !this.testMerchant) {
      this.$notifyError('All test sms inputs are required');
    }

    const testSmsPayload = {
      sms: this.editCustomSms.sms,
      smsParams: {
        donationAmount: Number(this.testDonation),
        offerId: this.testNotificationItemId,
        storeName: this.testMerchant,
        transactionAmount: Number(this.testPurchase)
      },
      smsType: 'sms_template_offer_redemption'
    };
    await AdminService.sendTestSms(testSmsPayload);
  }

  async getAdminOffers(): Promise<void> {
    try {
      this.loading = true;
      this.allOffers = await AdminService.fetchAdminOffers();

      this.loading = false;
    } catch (e: any) {
      this.$notifyError('Something went wrong');
    }
  }

  async created() {
    this.getAdminOffers();
  }
}
